import React from "react"
import isEqual from "lodash/isEqual"
import { graphql } from "gatsby"

import type { ChallengesPageType } from "../types/ChallengesPageType"
import type { AllLayoutsType, UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PrivateRoute,
  ROUTES,
  GetUserChallengesSummary,
  DATA_LAYER_SCRIPT,
  redirectTo,
} from "@lesmills/gatsby-theme-common"

import { withPreview } from "gatsby-source-prismic"

import Challenges from "../components/Challenges"

type Props = {|
  pageContext: {
    lang: string,
    layout: AllLayoutsType,
  },
  location: Object,
  data: ChallengesPageType,
|}

const ChallengesPage = (props: Props) => {
  const { pageContext, data, location } = props || {}
  const scriptsChallengesPage = [DATA_LAYER_SCRIPT]

  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  redirectTo(ROUTES(lang).LMPLUS_PLANS)

  const { prismicLayout = {}, prismicChallengesPage = {} } = data
  const pageContent = prismicChallengesPage.data || {}
  const layout = prismicLayout.data || {}

  const renderChallengesPage = ({ user, checkingSession }: UserType) => (
    <Layout
      data={layout}
      lang={lang}
      user={user}
      isLoading={checkingSession}
      altLogo={data.header_logo}
    >
      {renderSEO(
        pageContent,
        ROUTES(lang).CHALLENGES,
        lang,
        scriptsChallengesPage
      )}
      <Challenges
        data={pageContent}
        lang={lang}
        user={user}
        location={location}
        layoutData={layout}
      />
    </Layout>
  )

  return (
    <PrivateRoute
      component={renderChallengesPage}
      lang={lang}
      userQuery={GetUserChallengesSummary}
      {...props}
    />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutPublicPage
    }
    prismicChallengesPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicChallengesPageBodyAllChallengesInclude {
            id
            items {
              challenge_factor_icon {
                alt
                url
              }
              challenges_factor_name {
                raw
                text
              }
            }
            primary {
              challenges_include_title {
                raw
                text
              }
            }
          }
          ... on PrismicChallengesPageBodyChallengesList {
            id
            items {
              challenge_min_start_date
              challenge_author {
                raw
                text
              }
              challenge_description {
                raw
                text
              }
              challenge_duration {
                raw
                text
              }
              challenge_equipment_list {
                url
              }
              challenge_equipment_required {
                raw
                text
              }
              challenge_id {
                text
                raw
              }
              challenge_image {
                alt
                url
                thumbnails {
                  Black {
                    alt
                    url
                  }
                  Mobile {
                    alt
                    url
                  }
                  Tablet {
                    alt
                    url
                  }
                }
              }
              challenge_intensity {
                raw
                text
              }
              challenge_intensity_icon {
                url
                size
              }
              challenge_length {
                raw
                text
              }
              challenge_length_icon {
                url
                size
              }
              challenge_name {
                raw
                text
              }
              challenge_plan {
                raw
                text
              }
              challenge_thumbnail {
                alt
                url
                thumbnails {
                  Black {
                    alt
                    url
                  }
                  Mobile {
                    alt
                    url
                  }
                  Tablet {
                    alt
                    url
                  }
                }
              }
              challenge_video_url {
                url
              }
              challenge_vimeo_url {
                url
              }
              view_video_button_disable
              download_pack_button_display
              download_pack_link
            }
            primary {
              challenges_list_title {
                raw
                text
              }
            }
          }
        }
        accept_challenge_success_message {
          raw
          text
        }
        accept_challenge_success_title {
          raw
          text
        }
        accept_challenge_title {
          raw
          text
        }
        cancel_button_label {
          raw
          text
        }
        challenge_active_label {
          raw
          text
        }
        close_button_label {
          raw
          text
        }
        get_challenge_button_label {
          raw
          text
        }
        header_logo {
          alt
          url
          thumbnails {
            Black {
              alt
              url
            }
          }
        }
        hero_highlight_title {
          raw
          text
        }
        hero_image {
          alt
          url
          thumbnails {
            Mobile {
              alt
              url
            }
            Tablet {
              alt
              url
            }
          }
        }
        hero_video {
          raw
        }
        hero_title {
          raw
          text
        }
        intro_description {
          raw
          text
        }
        intro_title {
          raw
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        start_date_label {
          text
          raw
        }
        start_date_placeholder {
          text
          raw
        }
        stop_challenge_label {
          raw
          text
        }
        stop_challenge_message {
          raw
          text
        }
        stop_challenge_success_message {
          raw
          text
        }
        stop_challenge_success_title {
          raw
          text
        }
        stop_challenge_title {
          raw
          text
        }
        subscribe_challenge_label {
          raw
          text
        }
        subscribe_challenge_note {
          raw
          text
        }
        switch_challenge_button_label {
          raw
          text
        }
        switch_challenge_message {
          raw
          text
        }
        switch_challenge_title {
          raw
          text
        }
        view_workouts_button_label {
          raw
          text
        }
        challenges_swipe_guide {
          raw
          text
        }
        challenge_word {
          raw
          text
        }
        cancel_calendar_button_label {
          raw
          text
        }
        apply_calendar_button_label {
          raw
          text
        }
        unsubscribe_success_message {
          raw
          text
        }
        inactive_subscription_notification {
          raw
          text
        }
        unsubscribe_challenge_note {
          raw
          text
        }
        lmod_challenges_active_challenge_exist {
          raw
          text
        }
        lmod_challenges_accept_challenge_unhandled_error {
          raw
          text
        }
        lmod_challenges_switch_challenge_unhandled_error {
          raw
          text
        }
        lmod_challenges_no_existing_challenge {
          raw
          text
        }
        lmod_challenges_no_active_challenge {
          raw
          text
        }
        lmod_challenges_leave_challenge_unhandled_error {
          raw
          text
        }
        lmod_challenges_invalid_token {
          raw
          text
        }
        lmod_challenges_segment_error {
          raw
          text
        }
        lmod_challenges_unsubscribe_challenge_unhandled_error {
          raw
          text
        }
        download_pack_button_label
        sign_up_button_label
      }
    }
  }
`

export default withPreview(
  React.memo(ChallengesPage, (prevProps, nextProps) =>
    isEqual(prevProps.data, nextProps.data)
  )
)
